<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          title="搜索"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
      <div class="choose">
        <van-search
          v-model="value"
          placeholder="请输入关键词"
          @search="onSearch"
          show-action
          shape="round"
        >
          <template #action>
            <div @click="onSearch(value)">确定</div>
          </template>
        </van-search>
      </div>
    </div>
    <div class="content">
      <div class="content-search">
        <!-- 搜索列表 -->
        <div class="contentcart">
          <div class="contentcart-title">
            <div class="left">sss</div>
            <div class="right">
              <div class="righttext"></div>
              等待审批
            </div>
            <!-- <div class="rightthrough">
                <div class="righttext"></div>
                审批通过
              </div>
              <div class="rightnothrough">
                <div class="righttext"></div>
                审批不通过
              </div> -->
          </div>
          <div class="cart-content" @click="listDetails">
            <div class="cart-introduce">
              <div class="introduce-box">
                <span class="introduce-way">采购方式:</span
                ><span class="introduce-val">公开招标</span>
              </div>
              <div class="introduce-box">
                <span class="introduce-way">编制单位:</span
                ><span class="introduce-val">公开招标公开招标</span>
              </div>
              <div class="introduce-box">
                <span class="introduce-way">采购方式:</span
                ><span class="introduce-val">公开招标</span>
              </div>
            </div>
            <div class="cart-people">
              <span><van-icon name="contact" size="30" /></span>
              <span>张三</span>
            </div>
          </div>
        </div>
        <!-- 历史记录 -->
        <div class="record-box">
          <div class="record">
            <span class="recordleft">搜索历史</span>
            <div class="recordicon" @click="dele">
              <van-icon name="delete-o" size="13" /><span class="icontext"
                >清除</span
              >
            </div>
          </div>
          <div
            class="record-data"
            v-for="(item, index) in taa"
            :key="index"
            @click="onSearch(item)"
          >
            <span class="datarecord"
              ><van-icon name="underway-o" style="margin-right: 4px" />
              {{ item }}</span
            >
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      finished: true,
      isLoading: false,
      active: "f",
      value: "",
      taa: ["就设计师计算机是", "了是理论上劳斯莱斯"],
    };
  },
  methods: {
    dele() {
      this.taa = [];
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onRefresh() {
      this.loading = true;
    },
    onSearch(o) {
      this.value = o;
    },
    onLoad() { },
    listDetails() {
      this.$router.push({ name: "procurementDetails" });
    },
  },
};
</script>
<style lang="less">
.content-search {
  min-height: 100%;
  .record-box {
    padding: 0.3rem;
    .record {
      width: 100%;
      margin-top: 50px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .recordleft {
        color: #8f8f8f;
      }
      .recordicon {
        display: flex;
        align-items: center;

        .icontext {
          color: #2d8aff;
        }
      }
    }
    .record-data {
      font-size: 12px;
      color: #333;
      border: 1px solid rgb(240, 236, 236);
      line-height: 30px;
      padding: 0 0.3rem;
      display: flex;
      align-items: center;
      margin-top: 5px;
      justify-content: space-between;
      .datarecord {
        display: flex;
        align-items: center;
        margin-left: 2px;
      }
    }
  }
}
</style>
